<template>
  <div class="form">
    <van-form @submit="onSubmit">
      <van-field
        v-model="addrFormNew.userName"
        name="username"
        :label="labelName"
        placeholder="姓名/公司名称"
        :rules="regExp.other"
      />
      <van-field
        v-model="addrFormNew.mobile"
        name="mobile"
        label="联系电话"
        placeholder="联系电话"
        :rules="regExp.phone"
      />
      <van-field
        v-if="optionType === 'change' && addrType === 'deliver'"
        label="我的企业名称"
        :value="addrFormNew.companyName"
        :readonly="corpId !== ''"
        placeholder="企业名称"
      />
      <van-field
        v-model="getAddr"
        name="areaCode"
        label="所在地址"
        placeholder="省市区 街道"
        right-icon="location"
        readonly
        class="marTp30"
        :disabled="isLock"
        :rules="regExp.other"
        @click="changeCascader"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-if="areaOptions"
          v-model="addrFormNew.areaCode"
          title="请选择所在地区"
          :options="areaOptions"
          :field-names="fieldNames"
          :disabled="isLock"
          @close="show = false"
          @finish="onFinish"
        />
      </van-popup>
      <van-field
        v-model="addrFormNew.addrInfo"
        name="addrInfo"
        label="详细地址"
        placeholder="如道路、门牌号、单元室等"
        :disabled="isLock || ($store.state.drawerType !== 'add'&&!isBatch)"
        :rules="regExp.other"
      />
      <div
        class="sb_cont"
        :class="recognitionInfo === '' ? 'bg_gray' : 'bg_fff'"
      >
        <van-field
          v-model="recognitionInfo"
          rows="2"
          autosize
          type="textarea"
          class="marTp30"
          :placeholder="cpStr"
          :disabled="$store.state.drawerType !== 'add'&&!isBatch"
        />
        <div class="flex_lr_m" v-if="recognitionInfo !== ''">
          <span class="sb_btn" @click="clearTxt">清空</span
          ><span class="sb_btn c_blue" @click="checkRecognition">智能识别</span>
        </div>
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">
          {{ optionType === "add" ? "新增" : "保存" }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { getAddressDetail, getCreatedAddrM } from "@/assets/js/order";
import { getUserStore } from "@/assets/js/storage";
const userStore = getUserStore();
export default {
  name: "AddressEidtM",
  props: {
    isLock: {
      type: Boolean,
    },
    isBatch: {
      type: Boolean,
    },
    addrForm: {
      type: Object,
    },
    regExp: {
      type: Object,
      required: true,
    },
    addrType: {
      type: String,
      default: "deliver",
    },
    areaOptions: {
      type: Array,
      required: true,
    },
    optionType: {
      type: String,
    },
  },
  data() {
    return {
      // isBatchNew:this.isBatch,
      addrFormNew: this.addrForm,
      show: false,
      corpId: userStore.companyName || "",
      xhrData: {},
      recognitionInfo: "",
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
    };
  },
  watch: {
    // isBatch(newVal) {
    //   this.isBatchNew = newVal;
    // },
    addrForm(newVal) {
      this.addrFormNew = newVal;
    },
  },
  computed: {
    cpStr() {
      return this.addrType === "deliver"
        ? "试试粘贴发货人姓名、手机号、发货地址可以快速识别您的发货信息"
        : "试试粘贴收货人姓名、手机号、收货地址可以快速识别您的收货信息";
    },
    getAddr() {
      let newArr = "";
      let address = this.addrFormNew;
      newArr =
        (address.province || "") +
        "" +
        (address.city || "") +
        "" +
        (address.district || "");
      return newArr;
    },
    labelName() {
      return this.addrType === "deliver" ? "发货人" : "收货人";
    },
  },
  methods: {
    changeCascader() {
      if (this.isLock) {
        this.$toast("已锁定，不能修改省市区");
        return;
      }
      if (this.$store.state.drawerType !== "add" && ! this.isBatch) {
        this.$toast("修改或再下一单不能修改省市区");
        return;
      }
      this.show = true;
    },
    clearTxt() {
      this.recognitionInfo = "";
    },
    checkRecognition() {
      getAddressDetail(
        this,
        this.addrType,
        this.recognitionInfo,
        this.callbackReco
      );
    },
    callbackReco(data) {
      let address = getCreatedAddrM(data.addr, data.type);
      if (!address.mobile) {
        delete address.mobile;
      }
      if (!address.userName) {
        delete address.userName;
      }
      delete address.companyName;
      this.addrFormNew = {
        ...this.addrFormNew,
        ...address,
      };
    },
    onSubmit() {
      this.$emit("submit", this.addrFormNew);
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      let code = selectedOptions.map((option) => option.value);
      let name = selectedOptions.map((option) => option.label);
      let params = {
        areaCode: code,
        province: name[0] || "",
        city: name[1] || "",
        district: name[2] || "",
      };
      this.addrFormNew = {
        ...this.addrFormNew,
        ...params,
      };
    },
  },
};
</script>
<style scoped>
.form {
  padding: 20px;
  color: #666;
}
.marTp30 {
  margin-top: 30px;
}
.sb_cont {
  border-radius: 12px;
}
.bg_gray,
.bg_gray ::v-deep .van-cell {
  background-color: #e8e8e8;
}
.bg_fff,
.bg_fff ::v-deep .van-cell {
  background-color: #fff;
}
.borL {
  border-left: 5px solid #f5ce98;
  padding-left: 10px;
}
.linkColor {
  color: #009ad8;
}
.list {
  width: 100%;
  height: 113px;
  margin-top: 12px;
  background-color: #fff;
}
.noData {
  padding: 30px 0;
  width: 100%;
  text-align: center;
  color: #666;
}
.sb_btn {
  width: 25%;
  line-height: 70px;
  text-align: center;
}
.c_blue {
  color: #1989fa;
}
</style>
