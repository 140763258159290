<template>
  <div>
    <AddressEidtM
      :addrForm="addrFormNew"
      :regExp="regExp"
      :areaOptions="areaOptions"
      :addrType="addrType"
      :optionType="optionType"
      :isLock="isLock"
      :isBatch="isBatch"
      @submit="handleSubmit"
    ></AddressEidtM>
    <div>
      <van-row
        class="plr20"
        type="flex"
        justify="space-between"
        v-if="optionType === 'change'"
      >
        <van-col :span="6"><span class="borL">常用地址</span></van-col>
        <van-col :span="8" :style="{ 'text-align': 'right' }">
          <span class="linkColor" @click="() => addList()">
            从地址簿中选择
            <van-icon name="arrow" />
          </span>
        </van-col>
      </van-row>
    </div>
    <div class="mt20 block_base">
      <div
        class="addbox_li"
        v-for="item in addrList"
        :key="item.id"
        @click="() => handleSelectAddr(item)"
      >
        <div class="mb10">
          <span class="mr20">{{ item.contacts || "" }}</span>
          <span>{{ item.mobile || "" }}</span>
        </div>
        <div class="ellipsis">
          {{
            (item.areaCode1Name || "") +
            "" +
            (item.areaCode2Name || "") +
            "" +
            (item.areaCode3Name || "") +
            "" +
            (item.areaInfo || "")
          }}
        </div>
      </div>
    </div>
    <AddressListCmp
      :activeName="addrType"
      :xhrParams="xhrData"
      :isBatch="isBatch"
      :isRadio="true"
      :isShowAddrModal="isShowAddrModal"
      :selectedAddr="selectedAddr"
      @changeAddrModal="changeAddrModal"
      @confirm="handleConfirm"
      @changeFaAddOneAddr="changeAddOneAddr"
    ></AddressListCmp>
  </div>
</template>
<script>
import AddressListCmp from "../addressListCmp/AddressListCmp.vue";
import AddressEidtM from "@/components/addressManage/AddressEidtM.vue";
import { isMobile } from "@/assets/js/global";
import { regExp } from "@/assets/js/global";
import {
  tipsModal,
  getCreatedAddrM,
  returnCreatedAddrM,
  checkDuplicateAddr,
  newSelectAddress,
} from "@/assets/js/order";
import { getUserStore, getAreaFilterList } from "@/assets/js/storage";
const userStore = getUserStore();
export default {
  name: "AddressManage",
  components: {
    AddressEidtM,
    AddressListCmp,
  },
  props: {
    addActiveName: {
      //pc新增地址簿弹窗类型
      type: String,
      default: "deliver",
    },
    isShowPcAdd: {
      type: Boolean,
      default: false,
    }, //是否显示Pc的新增地址簿弹窗
  },
  data() {
    return {
      optionType: "add", //change:为选择地址，add:为增加地址，edit:为修改地址
      isBatch: false,
      faIdx: -1,
      isLock: false, //是否已锁单
      addrType: "deliver",
      newStepArr: {},
      addrForm: {
        addrId: "",
        userName: "",
        mobile: "",
        areaCode: [], // 选中的省市区
        province: "",
        city: "",
        district: "",
        addrInfo: "",
      },
      regExp: {
        other: regExp("other"),
        phone: regExp("phone"),
      },
      xhrData: {
        corpId: userStore.corpId || "",
        userId: userStore.id || "",
      },
      addChange: "", //新增时，可能被改变的optionType值，备份用
      newAddrId: "", //新增后地址簿后的id
      path: "", //父组件传来的路由，用于新增后跳转
      isShowAddrModal: false, //是否显示地址簿弹窗
      selectedAddr: [], //已选中的地址id数组
    };
  },
  watch: {
    addActiveName(newVal) {
      this.addrType = newVal;
    },
  },
  computed: {
    isM() {
      return isMobile();
    },
    addrFormNew() {
      return this.addrForm;
    },
    areaOptions() {
      let ldStartList = getAreaFilterList("AREA_LD_START");
      let ldEndList = getAreaFilterList("AREA_LD_END");
      console.log(this.addrType === "deliver" ? ldStartList : ldEndList);
      return this.addrType === "deliver" ? ldStartList : ldEndList;
    },
    addrList() {
      let type = this.addrType === "deliver" ? "faAddrList" : "shouAddrList";
      return this.$store.state.addrList[type];
    },
  },
  created() {
    let routeParams = this.$route.params;
    if (!!routeParams.addressType) {
      //选择地址簿
      this.addrType = routeParams.addressType || "deliver";
      this.addChange = routeParams.addressType || "deliver";
      this.newStepArr = routeParams.addrDatas || this.addrForm;
      this.optionType = routeParams.optionType || "add";
      this.allBatchDatas = routeParams.allBatchDatas || {};
      this.isBatch = routeParams.isBatch || false;
      this.faIdx = routeParams.faIdx;
      this.isLock =
        !!this.newStepArr.orderSn && this.newStepArr.dzdState === "0";
      this.addrForm = getCreatedAddrM(
        routeParams.addrDatas,
        routeParams.addressType
      );
    } else if (!!routeParams.path) {
      //从批量增加发货人来的新增
      this.path = routeParams.path;
      this.addrType = routeParams.type;
    }
  },
  methods: {
    addList() {
      this.isShowAddrModal = true;
    },
    handleSubmit(res) {
      if (this.optionType === "add") {
        this.getSave(res);
        //新增
      } else if (this.optionType === "change") {
        if (!!this.newAddrId) {
          res.addrId = this.newAddrId;
        }
        //选择地址
        let isNext = true;
        if (res !== null && this.isBatch) {
          //批量下单的地址簿选择，要查重
          let addrrName =
            (res.province || "") +
            "" +
            (res.city || "") +
            "" +
            (res.district || "") +
            "" +
            (res.addrInfo || "");
          isNext = checkDuplicateAddr(this, res.addrId, addrrName, this.faIdx);
        }
        if (!isNext) return;
        this.getSave(res);
        let oldName = returnCreatedAddrM(res, this.addrType);
        this.addrForm = res;
        let oneDatasObj = { ...this.newStepArr, ...oldName };
        let routerParams = {
          isBacth: this.isBacth,
          faIdx: this.faIdx,
          oneDatas: oneDatasObj,
        };
        let url = "m_payorder"; //跳极速下单首页
        if (this.isBatch && this.addrType === "receive") {
          url = "m_batchorder";
          this.allBatchDatas.shList[this.faIdx] = {
            ...this.allBatchDatas.shList[this.faIdx],
            ...oldName,
          };
          routerParams.allBatchDatas = this.allBatchDatas;
        } else if (this.isBatch && this.addrType === "deliver") {
          url = "m_batchorder";
          this.allBatchDatas.fhArr = {
            ...this.allBatchDatas.fhArr,
            ...oldName,
          };
          routerParams.allBatchDatas = this.allBatchDatas;
        }
        this.$router.push({
          name: url,
          params: routerParams,
        });
      }
    },
    fetchData() {
      const params = this.xhrData;
      this.$axios(
        "POST",
        "/portal/corpAddress/queryByCorpId",
        params,
        "params",
        "application/json"
      ).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            this.$store.state.addrList = res.data;
          }
        }
      });
    },
    getSave(res) {
      let params = {
        userId: userStore.id,
        corpId: userStore.corpId,
        areaCode1: res.areaCode[0] || "",
        areaCode2: res.areaCode[1] || "",
        areaCode3: res.areaCode[2] || "",
        areaInfo: res.addrInfo || "",
        addressType: this.addrType === "deliver" ? "2" : "1", //地址类型 1收货地址 2发货地址
        contacts: res.userName || "",
        mobile: res.mobile || "",
      };
      this.$axios(
        "POST",
        "/portal/corpAddress/save",
        params,
        "params",
        "application/json"
      ).then((res) => {
        let tipsTxt = this.optionType === "add" ? "新增" : "修改";
        if (res.code === "200") {
          tipsModal(this, "success", tipsTxt + "地址成功");
          if (this.optionType === "add") {
            this.fetchData();
            if (this.path === "/addressManage") {
              //从选择地址簿来的新增
              this.addrType = this.addChange;
              this.optionType = "change";
              this.newAddrId = res.data || "";
            } else {
              //从批量新增收货人来的新增
              this.$router.go(-1);
            }
          }
        } 
        // else {
        //   tipsModal(this, "error", tipsTxt + "地址失败，" + res.msg || "");
        // }
      });
    },
    handleConfirm(res) {
      const addr = res.obj;
      let newName = getCreatedAddrM(addr, this.addrType);
      delete newName.companyName;
      newName = {
        ...this.addrForm,
        ...newName,
      };
      this.addrForm = { ...newName };
    },
    changeAddrModal(flag) {
      this.isShowAddrModal = flag || false;
    },
    changeAddOneAddr(arr) {
      this.addrType = arr.activeName;
      this.optionType = arr.type;
      let resetData = {
        addrId: "",
        userName: "",
        mobile: "",
        areaCode: [], // 选中的省市区
        province: "",
        city: "",
        district: "",
        addrInfo: "",
      };
      this.addrForm = {
        ...this.addrForm,
        ...resetData,
      };
    },
    closeAdd() {
      this.$emit("closeAdd", false);
    },
    handleSelectAddr(item) {
      let aa = newSelectAddress(item, this.addrType);
      aa = getCreatedAddrM(aa, this.addrType);
      if (this.addrForm.companyName) {
        aa.companyName = this.addrForm.companyName;
      }
      this.addrForm = aa;
    },
  },
};
</script>
<style scoped>
.addbox {
  background-color: #fff;
  margin: 0 30px;
}
.addbox_li {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}
</style>